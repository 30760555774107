import React, { useState } from "react";
import thunder_logo from "assets/home/platform/thunder-logo.webp";
import incubator_1 from "assets/home/platform/incubator-1.webp";
import incubator_2 from "assets/home/platform/incubator-2.webp";
import award_1 from "assets/home/platform/awardd.webp";
import line from "assets/home/platform/line.png";
import chart from "assets/home/platform/chart_updated.webp";
import logo_1 from "assets/home/platform/partners-logo/logo-1.webp";
import logo_2 from "assets/home/platform/partners-logo/logo-2.webp";
import logo_3 from "assets/home/platform/partners-logo/logo-3.webp";
import logo_4 from "assets/home/platform/partners-logo/logo-4.webp";
import logo_5 from "assets/home/platform/partners-logo/logo-5.webp";
import logo_6 from "assets/home/platform/partners-logo/logo-6.webp";
import logo_7 from "assets/home/platform/partners-logo/logo-7.webp";
import logo_8 from "assets/home/platform/partners-logo/logo-8.webp";
import { HeroBtn } from "atoms/buttons";
import { useNavigate } from "react-router-dom";
import { ContactUs } from "..";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Platform = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const goToContact = () => {
    navigate("/contact-us");
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const checkFormStatus = (success: any) => {
    if (success === true) {
      toast.success("Form Submitted Successfully");
    } else {
      toast.warn("Something went wrong, Please try later!");
    }
  };

  const data = [
    {
      title: "Bacancy logo",
      logo: logo_7,
      classes: "h-[20px] xl:h-[25px] 2xl:h-[33px] shrink-0",
    },
    {
      title: "Bijlify logo",
      logo: logo_1,
      classes: "h-[20px] xl:h-[25px] 2xl:h-[33px] shrink-0",
    },
    {
      title: "ChargeMiles logo",
      logo: logo_2,
      classes: "h-[40px] xl:h-[45px] 2xl:h-[51px] shrink-0",
    },
    {
      title: "Evnnovator logo",
      logo: logo_3,
      classes: "h-[30px] xl:h-[40px] 2xl:h-[48px] shrink-0",
    },
    {
      title: "VerdeMobility logo",
      logo: logo_6,
      classes: "h-[15px] xl:h-[20px] 2xl:h-[24px] shrink-0",
    },
    {
      title: "Incredible Engineering logo",
      logo: logo_8,
      classes: "h-[20px] xl:h-[25px] 2xl:h-[33px] shrink-0",
    },
    {
      title: "Marine Electricals logo",
      logo: logo_4,
      classes: "h-[20px] xl:h-[25px] 2xl:h-[33px] shrink-0",
    },
    {
      title: "Pulse Energy logo",
      logo: logo_5,
      classes: "h-[50px] xl:h-[65px] 2xl:h-[76px] shrink-0",
    },
  ];

  return (
    <>
      <ToastContainer />
      <section id="home" className="bg-[#040001] overflow-hidden">
        <div className="bg-[length:500px_500px] md:bg-[length:700px_700px] lg:bg-[length:800px_800px] xl:bg-[length:1000px_1000px] bg-center bg-no-repeat bg-[url(assets/home/platform/circle.svg)] mt-12 text-white bladetop-padding-lg blade-bottom-padding-lg w-container platform-section relative">
          <div className="w-fit w-container h96 bgred blade-topmargin relative">
            <div className="w-full h-full bgred top-0 pt-24 md:pt-32 lg:pt-36 xl:pt-40 flex flex-col items-center justify-center bladetop-margin relative platform-text">
              <h3 className="font-medium max-w-xl 2xl:max-w-3xl text-center text-40 platform-text-appear opacity-0 translate-y-[16px]">
                Trusted partner for{" "}
                <span>
                  <img
                    className="inline h-8 md:h-10"
                    src={thunder_logo}
                    alt="Icon of thunder"
                  />
                </span>{" "}
                your EV charging network expansion services
              </h3>
              <h6 className="font-regular max-w-md lg:max-w-2xl 2xl:max-w-3xl opacity-0 translate-y-[16px] text-center mt-7 mb-8 2xl:text-base platform-text-appear ">
                With a strong commitment to excellence, innovation, and customer
                satisfaction, CPO Assist is the preferred choice for Electric
                Vehicle OEMs, Charge Point Operators (CPO), and Fleet Operators
                seeking reliable and next generation SaaS solutions for EV
                charger network deployment.
              </h6>
              <div
                onClick={toggleModal}
                className="platform-text-appear  opacity-0 translate-y-[16px]"
              >
                <HeroBtn
                  ariaLabel="Redirect to contact page"
                  type="button"
                  size="small"
                  text="Contact us"
                />
              </div>
              <div
                className={`fixed top-0 ${
                  showModal ? "flex" : "hidden"
                } bg-black bg-opacity-70 right-0 left-0 z-[999999] justify-center items-center wfull md:inset-0 h-[calc(100%)] md:max-h-full contact-us-popup`}
              >
                <ContactUs
                  toggle={showModal}
                  setToggle={setShowModal}
                  checkFormStatus={checkFormStatus}
                />
              </div>
              <div className="mt-14 md:mt-[86px] flex flex-col md:flex-row gap-14 platform-text-appear opacity-0 translate-y-[16px]">
                <div>
                  <div className="flex flex-col md:flex-row gap-8 md:gap-6 items-center justify-center">
                    <img
                      className="h-16 w-auto md:h-14"
                      src={incubator_1}
                      alt="AIC SMUTBI SIKKIM MANIPAL UNIVERSITY logo"
                    />
                    <img
                      className="h-16 w-auto md:h-14"
                      src={incubator_2}
                      alt="MOTION logo"
                    />
                  </div>
                  <div className="flex items-center justify-center gap-2 mt-4">
                    <img
                      className=""
                      src={line}
                      alt="Horizontal straight line"
                    />
                    <p
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg, #FFF 27.49%, rgba(255, 255, 255, 0.00) 110.05%)",
                      }}
                      className="text-[14px] font-regular text-transparent bg-clip-text text-center"
                    >
                      Incubation center
                    </p>
                    <img
                      className="rotate-180"
                      src={line}
                      alt="Horizontal straight line"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    className="h-16 w-auto md:h-14"
                    src={award_1}
                    alt="MG DEVELOPER PROGRAM AND GIANT logo"
                  />
                  <div className="flex items-center justify-center gap-2 mt-4">
                    <img src={line} alt="Horizontal straight line" />
                    <p
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg, #FFF 27.49%, rgba(255, 255, 255, 0.00) 110.05%)",
                      }}
                      className="text-[14px] font-regular text-transparent bg-clip-text"
                    >
                      Awards
                    </p>
                    <img
                      className="rotate-180"
                      src={line}
                      alt="Horizontal straight line"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-20 2xl:w-8/11 platform-text-appear opacity-0 translate-y-[16px] w-container border-gray border border-opacity-30 rounded-lg px-0 bg-white bg-opacity-10">
                <img
                  className=" rounded-lg p-1"
                  src={chart}
                  alt="Flowchart explaining Network Expansion Requirement"
                />
              </div>
            </div>
          </div>
        </div>

        {/* For desktop view */}

        <div className="mt16 blade-bottom-margin-lg lg:flex flex-col items-center gap-5 hidden">
          <h3
            style={{
              backgroundImage:
                "linear-gradient(90deg, #FDA172 0%, #FD6142 50%, #FCAC55 100%)",
            }}
            className="font-medium bg-clip-text text-transparent"
          >
            Trusted by
          </h3>
          <div className="w-full relative  gradientoverlay w-container">
            <div className="flex w-fit grid-cols-8  items-center justify-center gap-20 lg:gap-x-6 lg:translate-x-0 xl:gap-12 2xl:gap-16 w-container ">
              {data.map((elem, idx) => {
                return (
                  <div key={idx} className={elem.classes}>
                    <img
                      className="h-full w-auto"
                      src={elem.logo}
                      alt={elem.title}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* For mobile view */}

        <div className="mt-8 blade-bottom-margin-lg flex flex-col items-center gap-5 lg:hidden">
          <h3
            style={{
              backgroundImage:
                "linear-gradient(90deg, #FDA172 0%, #FD6142 50%, #FCAC55 100%)",
            }}
            className="font-medium bg-clip-text text-transparent"
          >
            Trusted by
          </h3>
          <div className="w-full relative  gradient-overlay">
            <div className="flex w-fit grid-cols-6 items-center gap-20 lg:gap-x-12 lg:translate-x-0 xl:gap-12 2xl:gap-20 w-container marquee">
              {data.map((elem, idx) => {
                return (
                  <div key={idx} className={elem.classes}>
                    <img
                      className="h-full w-auto"
                      src={elem.logo}
                      alt={elem.title}
                    />
                  </div>
                );
              })}
              {data.map((elem, idx) => {
                return (
                  <div key={idx} className={elem.classes}>
                    <img
                      className="h-full w-auto"
                      src={elem.logo}
                      alt={elem.title}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Platform;
