import React, { ReactNode } from "react";
import { NavLink, Link } from "react-router-dom";

const sizeMap = {
  small: "2xl:text-sm text-sm",
  base: "2xl:text-base text-sm",
  large: " text-base md:text-lg",
  xl: " text-base md:text-xl",
  xxl: " text-xl xl:text-2xl",
  "3xl": " text-xl md:text-2xl xl:text-3xl",
};

const colorMap = {
  dark: "text-[#351719]",
  light: "text-white",
};
type MasterAnchorProps = {
  size?: keyof typeof sizeMap;
  text: string;
  href: string;
  target?: "_self" | "_blank";
  className?: string;
};

export function TextAnchor({
  text,
  target,
  href,
  color = "dark",
  size = "base",
  className,
  ...rest
}: MasterAnchorProps & { color?: "dark" | "light" }) {
  const sizeClass: string = sizeMap[size];
  const colorClass: string = colorMap[color];
  return (
    <a
      href={href}
      // target={target}
      // to={href}
      {...rest}
      className={`${sizeClass} ${colorClass} ${className} text-opacity-70 hover:text-opacity-100 focus:outline-none 
                  focus:underline decoration-from-font underline-offset-4 focus:text-opacity-100 font-medium transition-opacity duration-200`}
    >
      {text}
    </a>
  );
}


export function TextNavAnchor({
  text,
  target,
  href,
  color = "dark",
  size = "base",
  className,
  ...rest
}: MasterAnchorProps & { color?: "dark" | "light" }) {
  const sizeClass: string = sizeMap[size];
  const colorClass: string = colorMap[color];
  return (
    <Link
      to={href}
      className={`font-medium hover:opacity-80  outline-[#EA5455] outline-2 outline-offset-4 rounded-sm ${sizeClass}`}
      // target={target}
      // to={href}
      // {...rest}
      // className={(navData) => {
      //   return `${
      //     navData.isActive ? "opacity-100" : "opacity60"
      //   } ${className} ${sizeClass}  ${colorClass} hover:opacity80 transition-all duration-300 font-medium`;
      // }}
    >
      {text}
    </Link>
  );
}

export function HeroAnchor({
  text,
  size = "base",
  href,
  target,
  ...rest
}: MasterAnchorProps) {
  const sizeClass: string = sizeMap[size];
  return (
    <Link to={href} target={target} {...rest} className={`${sizeClass} `}>
      {text}
    </Link>
  );
}
// control the outline of anchor
export function Anchor({
  href,
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
  href: string;
}) {
  return (
    <a className={className} href={href}>
      {" "}
      {children}
    </a>
  );
}

export function ExternalAnchor({
  href,
  children,
  className,
  label,
}: {
  href: string;
  className?: string;
  label: string

  children: ReactNode;
}) {
  return (
    <a
      className={className}
      aria-label={label}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
