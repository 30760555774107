import React from "react";
import { Outlet } from "react-router-dom";
import { PageHome, PageError404, ContactUs, PrivacyPolicy, CookiesPolicy, Blog1 } from "screens";

import { Header, Footer } from "organisms";
import Blog2 from "screens/blogs/blog-2";
import Blog3 from "screens/blogs/blog-3";
// import PrivacyPolicy from "screens/privacyPolicy";
// import CookiesPolicy from "screens/cookiesPolicy";

function ViewWithHeaderAndFooter() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default function routes() {
  return [
    {
      path: "/",
      element: <ViewWithHeaderAndFooter />,
      children: [
        {
          path: "/",
          element: <PageHome />,
        },
        {
          path: "/Importance-of-Community-EV-Chargers-vs-Personal-Chargers",
          element: <Blog1 />,
        },
        {
          path: "/Optimizing-Utilization-Ratio-of-EV-Charging-Network",
          element: <Blog2 />,
        },
        {
          path: "/The-Ultimate-Guide-to-Selecting-the-Right-EV-Charger-for-Your-Premises",
          element: <Blog3 />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/cookies-policy",
          element: <CookiesPolicy />,
        },
        // {
        //   path: "/contact-us",
        //   element: <ContactUs />,
        // },
        {
          path: "*",
          element: <PageError404 />,
        },
      ],
    },
  ];
}
