import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import grid from 'assets/home/feature-carousel/grid-2.svg'
import top_border from 'assets/home/feature-carousel/top-border.svg'
import image_1 from 'assets/home/feature-carousel/1.webp'
import image_2 from 'assets/home/feature-carousel/2.webp'
import image_3 from 'assets/home/feature-carousel/3.webp'
import image_4 from 'assets/home/feature-carousel/4.webp'
import image_5 from 'assets/home/feature-carousel/5.webp'
import bg_ellipse from 'assets/home/feature-carousel/ellipse-gradient.svg'

import discovering from 'assets/home/feature-carousel/icons/discovering-icon.webp'
import site from 'assets/home/feature-carousel/icons/site.webp'
import installation from 'assets/home/feature-carousel/icons/installation-icon.webp'
import maintenance from 'assets/home/feature-carousel/icons/maintenance-icon.webp'
import customer from 'assets/home/feature-carousel/icons/customer-icon.webp'

import icon_1_highlight from 'assets/home/feature-carousel/highlighted-icons/Discovering land.webp'
import icon_2_highlight from 'assets/home/feature-carousel/highlighted-icons/Site insights.webp'
import icon_3_highlight from 'assets/home/feature-carousel/highlighted-icons/Installation.webp'
import icon_4_highlight from 'assets/home/feature-carousel/highlighted-icons/Maintenance.webp'
import icon_5_highlight from 'assets/home/feature-carousel/highlighted-icons/Customer Support.webp'
import icon_bg from 'assets/home/feature-carousel/icon-background.svg'
import { HeroTransparentBtn } from 'atoms/buttons'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import gsap from 'gsap';
import { CSSRulePlugin, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);

const FeatureCarousel = () => {

 
  const wrapper = useRef<any>()
  const [activeIndex, setActiveIndex] = useState(0)
  let userExeperienceRef = useRef<HTMLDivElement>(null)
  let userExeperienceImageRef = useRef<HTMLDivElement>(null)

  const data = [
    {
      icon: discovering,
      title: "Site discovery",
      highlited_icon: icon_1_highlight,
      image: image_1,
      heding: "Discovering land simplified",
      desc: "Filter preferable locations from a wide database featuring important details such as power and space availability, and connect with landowners with ease."      
    },
    {
      icon: site,
      title: "Site survey",
      highlited_icon: icon_2_highlight,
      image: image_2,
      heding: "Site insights simplified",
      desc: "Get site survey reports with accurate data as we share technical insights covering the most vital parameters."      
    },
    {
      icon: installation,
      title: "Installation and commissioning",
      highlited_icon: icon_4_highlight,
      image: image_3,
      heding: "Installation and commissioning simplified",
      desc: "See your EV charging network expand as planned with timely installation backed by processes that adhere to all the technical, functional, and regulatory compliances"      
    },
    {
      icon: maintenance,
      title: "Maintenance",
      highlited_icon: icon_3_highlight,
      image: image_4,
      heding: "Annual and comprehensive maintenance",
      desc: "Keep malfunctions and heavy repair costs at bay with regular maintenance and servicing for an uninterrupted EV charging experience."      
    },
    {
      icon: customer,
      title: "Customer support",
      highlited_icon: icon_5_highlight,
      image: image_5,
      heding: "Troubleshooting simplified / Assistance simplified",
      desc: "Empower your EV charging network with a robust 24/7 customer support system at EV users’ disposal for unrestricted sustainability."      
    },
  ]

  useEffect(() => {
    gsap.to(wrapper.current, {
      scrollTrigger: {
        trigger: wrapper.current,
        start: 'top 60%',
        onEnter: () => {
          wrapper?.current?.classList.add('title-active')
        },
      },
    })
  }, [])

  

  const gradient = useRef<any>(null)
  useLayoutEffect(() => {

    

    const ctx = gsap.context(() => {

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".feature-carousel-section",
          start: "top 70%",
          end: "top 50%",
          scrub: 5
            
        }
      })

      tl.to('.svg-top',{
        width: "100%",
        ease: "Sine.easeInOut",
        stagger: 0.1,
      })
      .to(gradient.current,{
        delay: -0.5,
        top: "0%",
        ease: "Sine.easeInOut",
        stagger: 0.1,
      })
      
    })

    return () => ctx.revert()    

  }, [])

  return (
    <section id='services-and-tools' className='relative feature-carousel-section' style={{background: "linear-gradient(0deg, #0A0000 0%, #0A0000 100%), linear-gradient(0deg, #1E2124 0%, #1E2124 100%), #FFF"}}>
      <div className=' -translate-y1/2'>
        <svg className='svg-top  w-0 absolute top-[0%] z-[20] left-1/2 -translate-x-1/2' xmlns="http://www.w3.org/2000/svg" width="826" height="8" viewBox="0 0 826 8" fill="none">
          <path d="M0.692383 0L825.308 3.78836" stroke="url(#paint0_linear_289_517)" strokeWidth="7"/>
          <defs>
            <linearGradient id="paint0_linear_289_517" x1="825.308" y1="3.78836" x2="23.4717" y2="0.104651" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF8181" stopOpacity="0"/>
              <stop offset="0.464015" stopColor="#FF8181"/>
              <stop offset="1" stopColor="#FF8181" stopOpacity="0"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div ref={wrapper} className='minh-screen blade-top-padding-lg blade-bottom-padding-lg text-white featurecarousel  relative z-10 overflow-hidden'>
        <img ref={gradient} className='absolute -top-full left-1/2 -translate-x-1/2 -translatey-1/2 h0 origin-center pointer-events-none w-full' src={bg_ellipse} alt="An orange gradient" />
        <div className='w-container-sm md:w-container feature-carousel-content'>
          <div className='grid-gradient absolute top-0 pointer-events-none '>
            <img className='opacity-[.15]' src={grid} alt="white grid" />
          </div>
          <h3 className='font-medium text-40 text-center opacity-0'>Site discovery and EVSE <br /> <strong style={{backgroundImage: "linear-gradient(279deg, #FF6749 13.19%, #FDA172 41.09%, #FCAC55 96.88%)"}} className='bg-clip-text text-transparent'>deployment simplified</strong></h3>
          <p className='text-[#AEAEAE] font-regular mt-5 text-center opacity-0'>From the most fluid site scouting experience to hassle-free EV charging station <br className='hidden lg:block'/> installation across India, our five key services amplify the on-ground reach of our clients.</p>
    
          <div className='flex items-center justify-center flex-col w-full feature-carousel-content'>
            <div className='blade-top-margin flex justify-between md:justify-center w-full xl:w-fit md:gap-10 lg:gap-7 2xl:gap-12'>
              {
                data.map((elem,idx)=>{
                  return(
                    <button type='button' key={idx} onClick={() => setActiveIndex(idx)} className={`flex items-center gap-2 2xl:gap-3 cursor-pointer rounded-sm outline-[#EA5455] outline-offset-8 ${idx === activeIndex ? 'opacity-100': 'opacity-50'}`}>
                    
                      <div className='relative'>
                        <img className='h-12' src={elem.icon} alt={elem.title+'button'} />
                      </div>
                      <p className={`font-medium transition-opacity duration-500 hidden lg:block`}>{elem.title}</p>
                    </button>
                  )
                })
              }
            </div>

            <div className='blade-top-margin flex-col lg:flex-row flex items-center gap-12 justify-center'>
              <SwitchTransition>
                <CSSTransition 
                  nodeRef={userExeperienceImageRef}
                  key={activeIndex}
                  timeout={300}
                  classNames="designFeatures-image"
                >
                  <div ref={userExeperienceImageRef} className=''>
                    {/* <div className='relative w-fit rounded-[20px] p-2 blur-gradient'> */}
                      <img className='h-52 md:h-[450px] w-full lg:h-[330px] lg:w-[500px] xl:h-[380px] xl:w-[600px] 2xl:h-[591px] 2xl:w-[898px]' src={data[activeIndex].image} alt={data[activeIndex].title} />
                    {/* </div> */}
                  </div>
                </CSSTransition>
              </SwitchTransition>

              <SwitchTransition>
                <CSSTransition
                  nodeRef={userExeperienceRef}
                  key={activeIndex}
                  timeout={0}
                  classNames={""}
                >
                  <div ref={userExeperienceRef} className='shrink-0 max-w-none md:max-w-md lg:max-w-xs xl:max-w-[450px] transition-all duration-500 transition-text-wrapper'>
                    <div className='relative w-fit mb-8 hidden lg:block'><img className='h-16 xl:h-20 2xlh-full opacity-0 translate-y-4' src={data[activeIndex].highlited_icon} alt={data[activeIndex].title} /></div>
                    <h3 style={{backgroundImage: "linear-gradient(279deg, #FF6749 13.19%, #FDA172 41.09%, #FCAC55 96.88%)"}} className='bg-clip-text text-transparent font-medium mt8 opacity-0 translate-y-4 text-center lg:text-left'>{data[activeIndex].heding}</h3>
                    <p className='font-regular mt-3 mb-5 text-[#D7D7D7] opacity-0 translate-y-4 text-center lg:text-left'>{data[activeIndex].desc}</p>
                    <div className='opacity-0 translate-y-4 text-center flex justify-center lg:justify-start'>
                      <HeroTransparentBtn 
                          ariaLabel='Click to know about the product'
                          role="link"
                          size="small"
                          text="Know more"
                          type="button"
                      />
                    </div>
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureCarousel