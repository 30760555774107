import React from "react";
import './button.css'

const sizeMap = {
  small: "text-sm px-6 py-3",
  base: "2xl:text-base text-sm px-5 lg:px-6 2xl:py-4 py-3",
  large: "text-base md:text-lg",
  xl: " text-base md:text-xl",
  xxl: " text-xl xl:text-2xl",
  "3xl": " text-xl md:text-2xl xl:text-3xl",
};
const colorMap = {
  transparent:
    "!bg-transparent text-dark stroke-dark group-hover:stroke-white group-hover:text-white group-hover:border-transparent group-focus:border-transparent border border-black",
  black: "!bg-mirror text-white stroke-white border-transparent",
  blue: "!bg-blue text-white stroke-white border-transparent",
};

type MasterBtnProps<T extends "submit" | "button"> = {
  text: string;
  size: keyof typeof sizeMap;
  color?: keyof typeof colorMap;
  type: T;
  ariaLabel: string;
  isDisabled?: boolean;
  role?: "button" | "link";
  onClick?: T extends "submit" ? never : () => void;
};

const spaceMap = {
  small: "w-11 h-11",
  base: "2xl:w-14 md:w-12 w-11 2xl:h-14 md:h-10 h-11",
};
export function HeroBtn<T extends "submit" | "button">({
  type,
  role = "button",
  text,
  size,
  isDisabled,
  ariaLabel,
  color = "black",
  ...rest
}: MasterBtnProps<T>) {
  let space: "base" | "small" = "base";
  let isSmall = true;
  if (size === "base" || size === "small") {
    space = size;
  }
  const sizeClass: string = sizeMap[size];
  const colorClass: string = colorMap[color];
  const spaceClass: string = spaceMap[space];
  if (size === "base") isSmall = false;

  return (
    <button
      aria-label={ariaLabel}
      role={role}
      disabled={isDisabled}
      {...rest}
      type={type === "button" ? "button" : "submit"}
      className="flex gap-1 group relative disabled:cursor-not-allowed disabled:pointer-events-none disabled:select-none outline-2 rounded-3xl outline-[#EA5455] outline-offset-4"
    >
      <div
        className={`hero-btn grouphover:!bg-blue groupfocus:!bg-blue z-10 h-full  whitespace-nowrap transition-all duration-700 ease-in-out 
        ${sizeClass} bg-[#EA5455] text-white rounded-full group-hover:border-transparent group-focus:border-transparent  font-regular tracking-wide leading-snug`}
      >
        {text}
      </div>
    </button>
  );
}

export function HeroTransparentBtn<T extends "submit" | "button">({
  type,
  role = "button",
  text,
  ariaLabel,
  size,
  isDisabled,
  color = "black",
  ...rest
}: MasterBtnProps<T>) {
  let space: "base" | "small" = "base";
  let isSmall = true;
  if (size === "base" || size === "small") {
    space = size;
  }
  const sizeClass: string = sizeMap[size];
  const colorClass: string = colorMap[color];
  const spaceClass: string = spaceMap[space];
  if (size === "base") isSmall = false;

  return (
    <button
      role={role}
      aria-label={ariaLabel}
      disabled={isDisabled}
      {...rest}
      type={type === "button" ? "button" : "submit"}
      className="flex gap-1 group relative disabled:cursor-not-allowed disabled:pointer-events-none disabled:select-none w-fit rounded-3xl outline-[#EA5455] outline-offset-4"
    >
      <div
        className={`transparent-button group-hover:!bg-[#EA5455] groupfocus:!bg-[#EA5455] z-10 h-full  whitespace-nowrap transition-all duration-500 ease-in-out 
        ${sizeClass} bg-transparent border-[1.6px] border-white text-white rounded-full group-hover:border-[#EA5455] groupfocus:border-[#EA5455] group-hover:bg-[#EA5455] font-regular tracking-wide leading-snug`}
      >
        {text}
      </div>
    </button>
  );
}

export function HeroWhiteTransparentBtn<T extends "submit" | "button">({
  type,
  role = "button",
  text,
  ariaLabel,
  size,
  isDisabled,
  color = "black",
  ...rest
}: MasterBtnProps<T>) {
  let space: "base" | "small" = "base";
  let isSmall = true;
  if (size === "base" || size === "small") {
    space = size;
  }
  const sizeClass: string = sizeMap[size];
  const colorClass: string = colorMap[color];
  const spaceClass: string = spaceMap[space];
  if (size === "base") isSmall = false;

  return (
    <button
      aria-label={ariaLabel}
      role={role}
      disabled={isDisabled}
      {...rest}
      type={type === "button" ? "button" : "submit"}
      className="flex gap-1 group relative  outline-none disabled:cursor-not-allowed disabled:pointer-events-none disabled:select-none w-fit"
    >
      <div
        className={`white-transparent-button grouphover:!bg-[#EA5455] groupfocus:!bg-[#EA5455] z-10 h-full  whitespace-nowrap transition-all duration-500 ease-in-out 
        ${sizeClass} bg-transparent border-[1.6px] border-white text-white rounded-full grouphover:border-[#EA5455] groupfocus:border-[#EA5455] grouphover:bg-[#EA5455] font-regular tracking-wide leading-snug`}
      >
        {text}
      </div>
    </button>
  );
}