import React, { useLayoutEffect } from 'react'
import { RenderMarkdown } from 'organisms'

const content = `
This Privacy Policy sheds light on how we handle information gathered from visitors to our website, [www.cpoassist.com](http://cpoassist.com).

By navigating or using our website, you agree to the terms outlined in this Privacy Policy. If you don't agree with these terms, we kindly ask that you refrain from using our website.

[cpoassist.com](http://cpoassist.com) strives to provide an interactive and personalized experience to its visitors.

We may use personally identifiable information (such as your name and e-mail address) subject to the terms of this privacy policy. We hate spam as much as you do and we will never sell, barter, or rent your e-mail address to any unauthorized third party.


##### 1. General Terms of Use:
Like many other Web sites, [cpoassist.com](http://cpoassist.com) uses log files. The information inside the log files includes internet protocol (IP) addresses, type of browser, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and number of clicks to analyze trends, administer the site, track user’s movement around the site, and gather demographic information. IP addresses and other such information are not linked to any personally identifiable information.

As we adopt additional technology, we may also gather information through other means. In certain cases, you can choose not to provide us with information, for example by setting your browser to refuse to accept cookies, but this may result in being unable to access certain portions of the site or you may be asked to re-enter your user name and password.

##### 2. Information Collection:
2.1. Personal Information: We may collect personal information, such as your name, email address, and phone number, when provided voluntarily on our website.

2.2. Non-Personal Information: Additionally, we gather non-personal information like browser type, device details, and IP addresses to enhance the overall functionality of our website.


##### 3. How We Use Your Information:
3.1. Personal Information: Your personal information may be used to respond to queries, offer customer support, and share relevant updates about our products and services.

3.2. Non-Personal Information: Non-personal data aids us in analysis and statistics, contributing to the improvement of our website's functionality and user experience.


##### 4. Cookies and Tracking Technologies:
To enhance user experience and analyze trends, we employ cookies and similar tracking technologies. You can manage cookie preferences through your browser settings.


##### 5. Links to Third-Party Sites:
Our website may include links to third-party websites. Please note that we are not responsible for the privacy practices of these sites. We recommend reviewing the privacy policies of third parties to understand how they handle information.

##### 6. Data Security:
We implement industry-standard security measures to protect your information. While we strive for security, we cannot guarantee absolute protection due to inherent limitations in internet transmission and electronic storage.

##### 7. Children's Privacy:
Our website is not intended for individuals under 18 years of age. If you believe your child has provided personal information, please contact us, and we will take appropriate measures.

##### 8. Changes to Privacy Policy:
We reserve the right to update this Privacy Policy at any time. Changes become effective immediately upon posting the revised Privacy Policy on our website.

##### 9. Contact Us:
For questions or concerns about our Privacy Policy, please reach out to us at [legal@cpoassist.com](mailto:legal@cpoassist.com).
`

export default function PrivacyPolicy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='bg-[#040001] text-white'>
      <div className="pt-[4.5rem] md:pt-24 mb-4 lg:pt-32 lg:pb-10 ">
        <h3 className="font-medium text-center px-5 sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg leading-relaxed ">
          Privacy policy
        </h3>
      </div>
      <div className="markdown-wrapper md:max-w-xl lg:max-w-2xl xl:max-w-3xl 2xl:max-w-5xl sm:px-5 px-3 mx-auto blade-bottom-padding-lg ">
        <RenderMarkdown content={content} />
        <h3 className="font-medium text-center px-5 sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg leading-relaxed ">
        Thank you for choosing CPO Assist.
        </h3>
      </div>
    </div>
  )
}
