import React from "react";
import img1 from "assets/blog/blog-1-img.webp";
import img1Mob from "assets/blog/blog-1-img-mob.webp";
import img2 from "assets/blog/blog-2-img.webp";
import img3 from "assets/blog/blog-3-img.webp";
import { HeroWhiteTransparentBtn } from "atoms/buttons";
import { useNavigate } from "react-router-dom";

const BlogSection = () => {
  const navigate = useNavigate();
  const blog1 = () => {
    navigate("/Importance-of-Community-EV-Chargers-vs-Personal-Chargers");
  };
  const blog2 = () => {
    navigate("/Optimizing-Utilization-Ratio-of-EV-Charging-Network");
  };
  const blog3 = () => {
    navigate(
      "/The-Ultimate-Guide-to-Selecting-the-Right-EV-Charger-for-Your-Premises"
    );
  };

  return (
    <section
      id="blogs"
      className="bg-[#0A0000] min-h-screen blade-top-padding-lg blade-bottom-padding-lg"
    >
      <h3
        style={{
          backgroundImage:
            "linear-gradient(#FE9A86 100%, #FDA172 100%, #FDAB9B 100%, #FCAC55 100%)",
        }}
        className="text-48 bg-clip-text text-transparent font-bold text-center"
      >
        Blog
      </h3>
      <div className="w-container blade-top-margin grid grid-cols-1 md:grid-cols-2 max-w-[1000px] 2xl:max-w-[1249px] gap-x-8 gap-y-10 xl:gap-y-16">
        {/* Desktop view */}
        <div
          style={{
            boxShadow: "0px 4px 16px 0px rgba(255, 141, 141, 0.25) inset",
            background:
              "linear-gradient(127deg, rgba(255, 81, 111, 0.10) 12.74%, rgba(255, 105, 105, 0.00) 122.89%), linear-gradient(90deg, #1B0606 0.48%, #0B0000 99.89%), #1E2124",
            backgroundBlendMode: "normal,color,normal",
          }}
          className="hidden h-[360px] xl:h-[410px] 2xl:h-[542px] md:col-span-2 md:flex rounded-[30px] overflow-hidden backdrop-blur-xl border border-[#ffdfdf23]"
        >
          <div className="w[40%] lg:flex-shrink-0">
            <img
              className="w-full h-full object-cover object-center"
              src={img1}
              alt="Woman charging electric car at EV station"
            />
          </div>
          <div className='px-5 lg:px-10 py-20 flex flex-col gap-5 justify-end before:content-[""] before:pointer-events-none relative before:bgred before:w-full before:h-full before:absolute before:bg-[url(assets/home/whyCPOA/grid-bg.svg)] before:bg-repeat before:bg before:left-[50%] before:-translate-x-1/2 max-w-sm lg:max-w-none'>
            <p className="text-[#AEAEAE] font-medium">
              CPOA Community • 01-03-2023
            </p>
            <h3
              style={{
                backgroundImage:
                  "linear-gradient(279deg, #FF6749 13.19%, #FDA172 41.09%, #FCAC55 96.88%)",
              }}
              className="font-medium bg-clip-text text-transparent"
            >
              Importance of Community EV Chargers vs Personal Chargers
            </h3>
            <HeroWhiteTransparentBtn
              onClick={blog1}
              ariaLabel="Learn more"
              role="link"
              size="small"
              text="Read more"
              type="button"
            />
          </div>
        </div>
        {/* Mobile view */}
        <div
          style={{
            boxShadow: "0px 4px 16px 0px rgba(255, 141, 141, 0.25) inset",
            background:
              "linear-gradient(127deg, rgba(255, 81, 111, 0.10) 12.74%, rgba(255, 105, 105, 0.00) 122.89%), linear-gradient(90deg, #1B0606 0.48%, #0B0000 99.89%), #1E2124",
            backgroundBlendMode: "normal,color,normal",
          }}
          className="md:hidden mx-auto rounded-[30px] overflow-hidden backdrop-blur-xl bg-red md:col-span-1"
        >
          <img
            className="w-full"
            src={img1Mob}
            alt="Solar car charging station: eco-friendly EV recharging with solar power"
          />
          <div className="px-5 md:px-10 py-5 flex flex-col gap-2 justify-center max-w-md">
            <p className="text-[#AEAEAE] font-medium">
              CPOA Community • 01-03-2023
            </p>
            <h5
              style={{
                backgroundImage:
                  "linear-gradient(279deg, #FF6749 13.19%, #FDA172 41.09%, #FCAC55 96.88%)",
              }}
              className="font-medium bg-clip-text text-transparent"
            >
              Importance of Community EV Chargers vs Personal Chargers
            </h5>
            <HeroWhiteTransparentBtn
              onClick={blog1}
              ariaLabel="Read more"
              role="link"
              size="small"
              text="Read more"
              type="button"
            />
          </div>
        </div>
        <div
          style={{
            boxShadow: "0px 4px 16px 0px rgba(255, 141, 141, 0.25) inset",
            background:
              "linear-gradient(127deg, rgba(255, 81, 111, 0.10) 12.74%, rgba(255, 105, 105, 0.00) 122.89%), linear-gradient(90deg, #1B0606 0.48%, #0B0000 99.89%), #1E2124",
            backgroundBlendMode: "normal,color,normal",
          }}
          className="mx-auto pb-4 rounded-[30px] overflow-hidden backdrop-blur-xl bg-red md:col-span-1"
        >
          <img
            className="w-full"
            src={img2}
            alt="Solar car charging station: eco-friendly EV recharging with solar power"
          />
          <div className="px-5 md:px-10 py-5 flex flex-col gap-2 justify-center max-w-md">
            <p className="text-[#AEAEAE] font-medium">
              CPOA Community • 01-03-2023
            </p>
            <h5
              style={{
                backgroundImage:
                  "linear-gradient(279deg, #FF6749 13.19%, #FDA172 41.09%, #FCAC55 96.88%)",
              }}
              className="font-medium bg-clip-text text-transparent"
            >
              Methods to Optimize Utilization Ratio of EV Charging Network:{" "}
              <span className="text-white">An In-depth study</span>
            </h5>
            <HeroWhiteTransparentBtn
              onClick={blog2}
              ariaLabel="Read more"
              role="link"
              size="small"
              text="Read more"
              type="button"
            />
          </div>
        </div>
        <div
          style={{
            boxShadow: "0px 4px 16px 0px rgba(255, 141, 141, 0.25) inset",
            background:
              "linear-gradient(127deg, rgba(255, 81, 111, 0.10) 12.74%, rgba(255, 105, 105, 0.00) 122.89%), linear-gradient(90deg, #1B0606 0.48%, #0B0000 99.89%), #1E2124",
            backgroundBlendMode: "normal,color,normal",
          }}
          className="mx-auto pb-4 rounded-[30px] overflow-hidden backdrop-blur-xl bg-red md:col-span-1"
        >
          <img
            className="w-full"
            src={img3}
            alt="An EV charging at a wall: A car plugged in, ready to power up"
          />
          <div className="px-5 md:px-10 py-5 flex flex-col gap-2 justify-center max-w-md">
            <p className="text-[#AEAEAE] font-medium">
              CPOA Community • 01-03-2023
            </p>
            <h5
              style={{
                backgroundImage:
                  "linear-gradient(279deg, #FF6749 13.19%, #FDA172 41.09%, #FCAC55 96.88%)",
              }}
              className="font-medium bg-clip-text text-transparent"
            >
              The Ultimate Guide to Selecting the Right EV Charger for Your
              Premises
            </h5>
            <HeroWhiteTransparentBtn
              onClick={blog3}
              ariaLabel="Read more"
              role="link"
              size="small"
              text="Read more"
              type="button"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
