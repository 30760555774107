import React, { DispatchWithoutAction, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logomark from "assets/globals/logo.svg";
import { TextAnchor, TextNavAnchor } from "atoms/links";
import { CSSTransition } from "react-transition-group";
import gsap from "gsap";
import { ContactUs } from "screens/index";
import { toast } from "react-toastify";

export default function MobileNavModal({
  toggle,
}: {
  toggle: DispatchWithoutAction;
}) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const navigateTo = (target: string) => {
    navigate(target);
    toggle();
  };
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const dropdownRef = useRef<any>();

  const toggleDropdown = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const checkFormStatus = (success:any) => {
    if (success === true) {
        toast.success("Form Submitted Successfully")
    }else{
        toast.warn('Something went wrong, Please try later!')
    }
  }

  return (
    /* eslint-disable */
    <nav className="bg-white h-full block lg:hidden">
      <section className="w-container min-h-full text-black overflow-auto flex flex-col flex-nowrap z-[999]">
        <div className="flex-0 flex border-white border-opacity-10 border-b-2 flex-nowrap gap-5 items-center justify-between py-2">
          <img src={logomark} alt="CPO Assist full logo" className="h-10" />

          <button
            type="button"
            onClick={toggle}
            className="rounded-full stroke-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              className="stroke-[#EA5455] opacity-80 w-7 h-7"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col flex-1 pt-4">
          <ul className="list-none grid gap-3">
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="dark"
                text="Ecosystem"
                href="/#ecosystem"
                className="block py-2"
              />
            </li>
            {/* <li>
              <button
                onClick={toggleDropdown}
                type="button"
                className="dropdown-toggle font-medium text-black text-md border-none leading-none flex justify-between w-full items-center opacity-80"
              >
                Solutions
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className={`w-6 transition-all duration-500 
                              ${isOpenMenu ? "-rotate-90" : ""} `}
                >
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </button>

              <CSSTransition
                in={isOpenMenu}
                timeout={400}
                unmountOnExit
                onEnter={(node: any) =>
                  gsap.fromTo(
                    node,
                    { height: 0 },
                    { height: "auto", duration: 0.5 }
                  )
                }
                onExiting={(node: any) =>
                  gsap.to(node, { height: 0, duration: 0.5 })
                }
              >
                <div className="dropdow overflow-hidden" ref={dropdownRef}>
                  <button
                    type="button"
                    onClick={() => setIsOpenMenu(false)}
                    className="flex flex-col gap-3 mt-2 bg-white px-4 pt-4 pb-5 w-full"
                  >
                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="dark"
                        href="/solutions/product-development"
                        text="Product Development / Scaling"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>

                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="dark"
                        href="/solutions/business-process-transformation"
                        text="Business Process Transformation"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>

                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="dark"
                        href="/solutions/engineering-sustainability"
                        text="Engineering Sustainability"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>

                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="dark"
                        href="/solutions/business-innovation"
                        text="Business Innovation"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>

                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="dark"
                        href="/solutions/data-management"
                        text="Data Management"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>
                  </button>
                </div>
              </CSSTransition>
            </li> */}
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="dark"
                text="Services & Tools"
                href="/#services-and-tools"
                className="block py-2"
              />
            </li>
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="dark"
                text="About us"
                href="/#about-us"
                className="block  py-2"
              />
            </li>
            {/* <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="dark"
                text="About"
                href="/about"
                className="block  py-2"
              />
            </li>
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="dark"
                text="Culture"
                href="/culture"
                className="block  py-2"
              />
            </li> */}
            {/* <li 
            onClick={toggle}
            >
              <TextAnchor
                size="base"
                color="dark"
                text="Blogs"
                href="/coming-soon"
                className="block  py-2"
              />
            </li> */}
          </ul>
        </div>
        <div className="mt-auto pt-10 pb-1">
          <button
            role="link"
            type="button"
            onClick={toggleModal}
            className="bg-[#EA5455] hero-btn text-white  text-lg font-regular mb-3  pt-3 pb-4 px-3 rounded-full group-hover:border-transparent group-focus:border-transparent  w-full hover:bg-opacity-90 "
          >
            Contact us
          </button>
        </div>
        <div
                                    className={`fixed top-0 ${showModal ? "flex" : "hidden"} bg-black bg-opacity-70 right-0 left-0 z-[999999] justify-center items-center wfull md:inset-0 h-[calc(100%)] md:max-h-full contact-us-popup`}
                                >
                                    <ContactUs toggle={showModal} setToggle={setShowModal} checkFormStatus={checkFormStatus} />
                                            
                                </div>
      </section>
    </nav>
  );
}
