import { Formik } from 'formik'
import React, { Fragment, useLayoutEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
// import { MasterBtn } from 'atoms/buttons'
import emailjs from '@emailjs/browser'
import { HeroBtn } from 'atoms/buttons'
import logo from 'assets/contact/logo.webp'
import orange_ellipse from 'assets/contact/bg-ellipse.svg'
import cross from 'assets/home/platform/popup-cross.webp'

import './contact.css'

import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)


interface FormFields {
  email: string
  phoneNumber: string
  name: string
  organisationName: string
  message: string
  interest: string
}

const services = [
  {
    title: 'Select option',
    id: 'none',
  },
  {
    title: 'Site Discovery',
    id: 'site-discovery',
  },
  {
    title: 'Site Survey',
    id: 'site-survey',
  },
  {
    title: 'Installation & Commissioning',
    id: 'installation-&-commissioning',
  },
  {
    title: 'Annual Maintenance Services',
    id: 'annual-maintenance-services',
  },
  {
    title: 'Customer Support',
    id: 'customer-support',
  },
  {
    title: 'GROW (Workflow Mangament Tool)',
    id: 'GROW-(Workflow Mangament Tool)',
  },
  {
    title: 'LIFE (Site Listing Platform)',
    id: 'LIFE-(Site Listing Platform)',
  },
]



export default function Form({toggle,setToggle,checkFormStatus}:{toggle?: any; checkFormStatus?:any; setToggle: (value: boolean) => void }) {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 })
    })

    return () => ctx.revert()
  }, [])

  const [isLoading, setLoading] = useState<boolean>(false)
  const [service, setservice] = useState(services[0].title)
  const [contactStatus, setcontactStatus] = useState("hidden")
  const initialFormState: FormFields = {
    email: '',
    phoneNumber: '',
    name: '',
    organisationName: '',
    message: '',
    interest: service,
  }
  // console.log(service)

  

  const submitHandler = (values: any, { resetForm }: any) => {
    setLoading(true)
    values.interest = service
    
    initialFormState.interest = service
    emailjs
      .send('service_2sxbiw9', 'template_d7pv9vf', values, 'o5s2WQwFbitJ39Cdv') 
      .then(
        (result: any) => {
          checkFormStatus(true)
        },
        (error: any) => {
        },
      )
      .finally(() => {
        resetForm()
        setLoading(false)
        setToggle(false)
      })
  }
  const validator = (values: FormFields) => {
    const errors: any = {}
    if (!values.email) {
      errors.email = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    if (String(values.phoneNumber).toString().length !== 10) {
      errors.phoneNumber = 'Enter valid phone number (10 digit)'
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Please enter your contact number'
    }
    if (!values.name) {
      errors.name = 'Please enter your name'
    }
    if (!values.organisationName) {
      errors.organisationName = 'Please enter your organisation name'
    }
    return errors
  }

  const toggleModal = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <section className={`w-full overflow-hidden relative z-[10]  bg[#040001] lg:w[60%] md:px-4 bladetop-padding md:blade-top-padding-s blade-bottom-paddinglg md:blade-bottom-padding-s 2xl:bladetop-padding-lg 2xl:bladebottom-padding-lg `}>
        <section className="w-container  hscreen max-w-[650px] mx-auto relative  px-3 md:px-0 md:w-11/12 flex flex-col items-center mt24 md:mt32 2xl:mt28 ">
          <Formik
            initialValues={initialFormState}
            validate={(values) => validator(values)}
            onSubmit={(values, actions) => submitHandler(values, actions)}
            validateOnChange={false}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className="md:w-11/1 w-full lg:w-full mt10 lg:mt16 md:px- z-10 border-opacity-10 border-[#FCAC55] border-2 rounded-[42px] p-3 md:p-4"
              >
                <div style={{backdropFilter: "blur(35.53528594970703px)"}} className='border-[#ffdfdf28] contact-us-popup-scroll h-[600px] md:h-fit overflow-y-auto md:overflow-hidden border-[0.756px] rounded-[22.962px] p-4 md:p-7 bg-[#261313] relative after:content-[""] after:pointer-events-none after:z-[999] after:absolute after:top-0 after:left-1/2 after:-translate-x-1/2 after:opacity-25 after:h-full after:w-full after:bg-[url(assets/home/feature-carousel/grid-2.svg)] after:bg-no-repeat after:bg-contain'>
                <img className='absolute pointer-events-none -top-28 md:top-0 dw-full left-1/2 z[999] -translate-x-1/2 max-sm:h-96' src={orange_ellipse} alt="An orange ellipse gradient" />
                  <div className='flex justify-between items-center mb-7'>
                    <h3  className="mb-2 relative leading-tight text-white font-medium text-center">
                                                Contact us
                                            </h3>
                                            <button
                                                onClick={toggleModal}
                                                type="button"
                                            >
                                                <img src={cross} alt="" />
                                            </button>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y8 md:gap-y8 gap-6">
                    <label
                      className="flex flex-col text-white gap-1 text-sm font-light focus-within:text-[#FFCB92]"
                      htmlFor="candidatename"
                    >
                      Name 
                      <input
                        className={`${
                          errors.name
                            ? 'border-b-2 border-[#f12626]'
                            : 'border-white'
                        } bg-[#241A1A] text-sm font-regular font-normal border-opacity-10 
                        tracking-wide transition-all duration-300 outline-none focus:outline-none focus:border-1 
                        focus:border-[#FFCB92] border-1 focus:border-opacity-100  py-[10px] rounded-md pl-2 text-white`}
                        id="candidatename"
                        size={1}
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <small className="text-[#f12626] tracking-wider font-light flex flex-nowrap items-center gap-1">
                          {errors.name}
                        </small>
                      )}
                    </label>
                    <label
                      className="flex flex-col text-white gap-1 text-sm font-light focus-within:text-[#FFCB92]"
                      htmlFor="candidateorganisationName"
                    >
                      Organisation name 
                      <input
                        className={`${
                          errors.organisationName
                            ? 'border-b-2 border-[#f12626]'
                            : 'border-white border-opacity-10'
                        } bg-[#241A1A] text-sm font-regular font-normal border-opacity-10 
                        tracking-wide transition-all duration-300 outline-none focus:outline-none focus:border-1 
                        focus:border-[#FFCB92] border-1 focus:border-opacity-100  py-[10px] rounded-md pl-2 text-white`}
                        id="candidateorganisationName"
                        size={1}
                        name="organisationName"
                        type="text"
                        value={values.organisationName}
                        onChange={handleChange}
                      />
                      {errors.organisationName && (
                        <small className="text-[#f12626] tracking-wider font-light flex flex-nowrap items-center gap-1">
                          {errors.organisationName}
                        </small>
                      )}
                    </label>
                  </div>
  
                  <div className="grid gridcols-2 w-full mt- md:gap-y8 gap-6">
                    <label
                      className="flex text-white flex-col gap-1 mt-6 md:mt7 text-sm font-light focus-within:text-[#FFCB92]"
                      htmlFor="candidateEmail"
                    >
                      E-mail 
                      <input
                        className={`${
                          errors.email
                            ? 'border-[#f12626]'
                            : 'border-white border-opacity-10'
                        } bg-[#241A1A] text-sm font-regular font-normal border-opacity-10 
                        tracking-wide transition-all duration-300 outline-none focus:outline-none focus:border-1 
                        focus:border-[#FFCB92] border-1 focus:border-opacity-100  py-[10px] rounded-md pl-2 text-white`}
                        size={1}
                        name="email"
                        id="candidateEmail"
                        type="email"
                        inputMode="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <small className="text-[#dd4b41] tracking-wider font-light flex flex-nowrap items-center gap-1">
                          {errors.email}
                        </small>
                      )}
                    </label>
                    
                    <div className='w-full mt6 gap-4 grid grid-cols-1 md:grid-cols-2'>
                    <label
                      className="flex text-white flex-col gap-1 mt4 md:mt7 text-sm 
                    font-light focus-within:text-[#FFCB92]"
                      htmlFor="candidatephoneNumber"
                    >
                      Contact number 
                      <input
                        className={`${
                          errors.phoneNumber
                            ? 'border-[#f12626]'
                            : 'border-white border-opacity-10'
                        } bg-[#241A1A] text-sm font-regular font-normal border-opacity-10 
                        tracking-wide transition-all duration-300 outline-none focus:outline-none focus:border-1 
                        focus:border-[#FFCB92] border-1 focus:border-opacity-100  py-[10px] rounded-md pl-2 text-white`}
                        size={1}
                        inputMode="tel"
                        name="phoneNumber"
                        id="candidatephoneNumber"
                        type="number"
                        value={values.phoneNumber}
                        onChange={handleChange}
                      />
                      {errors.phoneNumber && (
                        <small className="text-[#f12626] tracking-wider font-light flex flex-nowrap items-center gap-1">
                          {errors.phoneNumber}
                        </small>
                      )}
                    </label>
                      <label
                        className="text-white flex flex-col gap1  md:mt7 text-sm font-light  focus-within:text-[#FFCB92]"
                        htmlFor="email"
                      >
                        Interested to know about
                        <Listbox
                          name='interest'
                          value={service}
                          onChange={(elem:any) => {
                            setservice(elem.title)
                          }}
                        >
                          <div className="relative mt-1">
                            <Listbox.Button value={service} className="bg-[#241A1A] focus:border-[#FFCB92] px-3 py-[10px] rounded-lg  w-full text-left focus:outline-violet border-1 border-white focus:borderopacity-100 border-opacity-10">
                              <span className="block truncate ">
                                <span
                                  className={`text-white font-regular  `}
                                >
                                  {service}
                                </span>
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="white"
                                  className="w-5 h-5 opacity-40 "
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute mt-1  max-h-60 w-full border z-20 dropdown-options border-[#fff] bg-[#241A1A] border-opacity-10 overflow-auto rounded-md  py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {services.map((interest) => (
                                  <Listbox.Option
                                    key={interest.id}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-3 pr-2 first:opacity-40 font-regular ${
                                        active
                                          ? 'bg-[#040001] text-white bg-opacity-40'
                                          : 'text-white'
                                      }`
                                    }
                                    value={interest}
                                  >
                                    {({ selected }) => (
                                      <span
                                        className={`block truncate ${
                                          selected ? 'font-medium' : 'font-normal'
                                        }`}
                                      >
                                        {interest.title}
                                      </span>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </label>
                    </div>
                  </div>
  
                  <div className="mt-6 md:mt-6">
                    <label
                      className="flex text-white flex-col gap-1 mt-4 md:mt-7 text-sm font-light focus-within:text-[#FFCB92]"
                      htmlFor="candidateMessage"
                    >
                      Message
                      <textarea
                        className="border-white border-opacity-10sm   bg-[#241A1A] text-sm font-regular font-normal border-opacity-10 
                      tracking-wide transition-all duration-300 outline-none focus:outline-none focus:border-1 
                      focus:border-[#FFCB92] border-1 py-[10px] rounded-md pl-2 text-white"
                        cols={7}
                        rows={3}
                        name="message"
                        id="candidateMessage"
                        aria-label="Message"
                        value={values.message}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div  className="mt-6 flex">
                    <HeroBtn
                      ariaLabel="Submit the query form"
                      type="submit"
                      text="Submit"
                      size="small"
                      // onClick={toggleModal}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </section>
      </section>
    </>
  )
}
