import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,Navigation, Autoplay } from "swiper";
import work_management_tool from "assets/home/cardOnScroll/WORKFLOW-MANAGEMENT-TOOL.svg";
import site_listening_tool from "assets/home/cardOnScroll/SITE-LISTING-TOOL.svg";

import "swiper/css";
import "swiper/css/pagination";
import { HeroBtn } from "atoms/buttons";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const CardsOnScroll = () => {
  const component = useRef<HTMLDivElement>(null);
  const swiperSlideRef = useRef<any>(null);
  const navigator = useRef<any>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const moveNext = () =>{
    swiperSlideRef.current.swiper.slideTo(1)
  }

  const movePrev = () =>{
    swiperSlideRef.current.swiper.slideTo(0)
  }

  const data = [
    {
      // logo: c_logo,
      title: "LIFE",
      fullTitle: "Location intelligence for EVSE",
      heading: "Discover sites, connect with land owners, scale.",
      desc: "One-stop aggregation platform that helps CPOs and fleet operators discover potential charging sites across India. With enhanced filters and detailed insights, the platform optimises site discovery for quicker deployment of EV charging stations.",
      buttonText: "Start discovering",
      chartImage: site_listening_tool,
    },
    {
      // logo: c_logo,
      title: "GROW",
      fullTitle: "Get realtime workflow",
      heading:
        "Optimising people, processes, and operations management in one go.",
      desc: "Our workflow management tool streamlines field operations during charging station installation across multiple sites. It synchronises the back office and the on-field staff with real-time coordination and project tracking keeping every stakeholder on top of things.",
      buttonText: "Get the tool",
      chartImage: work_management_tool,
    },
    
  ];

  return (
    <section ref={component}>
      <div className="hscreen bg-[#0A0000] flex items-center justify-center card-on-scroll">
        {/* Desktop View */}
        <div className="w-full hidden lg:block blade-top-padding blade-bottom-padding">
          <Swiper
            ref={swiperSlideRef}
            slidesPerView={"auto"}
            centeredSlides={true}
            grabCursor={true}
            modules={[Pagination,Autoplay, Navigation]}
            navigation={{
              nextEl: '.cardOnScroll-swiper-next',
              prevEl: '.cardOnScroll-swiper-prev',
            }}
            // autoplay={true}
            // pagination={true}
            speed={1000}
            spaceBetween={80}
            className="w-full pb16"
          >
            {data.map((elem, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="card-gradient min-h[600px] relative w-fit rounded-3xl flex flex-col lg:flex-row justify-between lg:gap-12 2xlgap-44 items-center blade-top-padding-sm blade-bottom-padding-sm px-5 xl:px-10 2xl:px-16 overflow-hidden"
                >
                  <div className="max-w-none md:max-w-md xl:max-w-md 2xl:max-w-lg shrink-0">
                    <div className="flex items-center justify-center lg:justify-start gap-3 mb-6">
                      <h5 className="text-[#EA5455] font-bold">
                        {elem.title}
                      </h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="30" viewBox="0 0 4 38" fill="none">
                        <path d="M2.2959 1.70312L2.2959 36.209" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                      </svg>
                      <h5 className="font-medium">{elem.fullTitle}</h5>
                    </div>
                    <h3 className="text40 font-medium leading-[130%] mb-3 text-center lg:text-left">
                      {elem.heading}
                    </h3>
                    <p className="text-[#4D4D4D] font-regular mb-9 text-center lg:text-left">
                      {elem.desc}
                    </p>
                    <HeroBtn
                      ariaLabel={elem.buttonText}
                      role="link"
                      size="small"
                      text={elem.buttonText}
                      // onClick={}
                      type="button"
                    />
                  </div>
                  <div className="border border-[#e9e9e938] p-5 rounded-xl shadow-[0px_0px_4px_1px_#FCEFE5] mt-10 lg:mt-0">
                    <img
                      className="pointer-events-none h-80 2xl:h-full"
                      src={elem.chartImage}
                      alt="Chart which shows the query plan"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="blade-top-margin-sm flex items-center justify-end w-container pr-14 xl:pr-9 2xl:pr-40">
            <div className="flex gap-3">
              <button aria-label="Move to previous slide" onClick={movePrev} className={`cardOnScroll-swiper-prev white-transparent-button group hover:!bg-[#fff] w-10 h-10 bg-[#D9D9D9] rounded-full cursor-pointer flex items-center  justify-center disabled:opacity-50  disabled:hover:shadow-none disabled:hover:bg-[#D9D9D9] disabled:cursor-not-allowed outline-[#EA5455] outline-offset-4`}>
                <svg className="hover:!fill-[#EA5455]" xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 25 21" fill="none">
                  <path d="M9.40119 1.58224L10.4834 0.5L12.6479 2.66447L11.5656 3.7467L9.40119 1.58224ZM2.44824 10.6996L1.36602 11.7819L0.283798 10.6996L1.36602 9.61742L2.44824 10.6996ZM11.5656 17.6526L12.6479 18.7348L10.4834 20.8993L9.40119 19.817L11.5656 17.6526ZM23.1101 9.16913H24.6406V12.2302H23.1101V9.16913ZM11.5656 3.7467L3.53047 11.7819L1.36602 9.61742L9.40119 1.58224L11.5656 3.7467ZM3.53047 9.61742L11.5656 17.6526L9.40119 19.817L1.36602 11.7819L3.53047 9.61742ZM2.44824 9.16913H23.1101V12.2302H2.44824V9.16913Z" fill="black"/>
                </svg>
              </button>
              <button aria-label="Move to next slide" onClick={moveNext} className="cardOnScroll-swiper-next rotate-180 white-transparent-button group hover:!bg-[#fff] w-10 h-10 bg-[#D9D9D9] rounded-full cursor-pointer flex items-center justify-center disabled:opacity-50 disabled:hover:shadow-none disabled:hover:bg-[#D9D9D9] disabled:cursor-not-allowed outline-[#EA5455] outline-offset-4  ">
                <svg className="hover:!fill-[#EA5455]" xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 25 21" fill="none">
                  <path d="M9.40119 1.58224L10.4834 0.5L12.6479 2.66447L11.5656 3.7467L9.40119 1.58224ZM2.44824 10.6996L1.36602 11.7819L0.283798 10.6996L1.36602 9.61742L2.44824 10.6996ZM11.5656 17.6526L12.6479 18.7348L10.4834 20.8993L9.40119 19.817L11.5656 17.6526ZM23.1101 9.16913H24.6406V12.2302H23.1101V9.16913ZM11.5656 3.7467L3.53047 11.7819L1.36602 9.61742L9.40119 1.58224L11.5656 3.7467ZM3.53047 9.61742L11.5656 17.6526L9.40119 19.817L1.36602 11.7819L3.53047 9.61742ZM2.44824 9.16913H23.1101V12.2302H2.44824V9.16913Z" fill="black"/>
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile View */}
        <div className="w-container-sm md:w-full lg:hidden blade-top-padding blade-bottom-padding">
          <Swiper
            pagination={true}
            modules={[Pagination]}
            spaceBetween={30}
            className="pb-16"
          >
            {data.map((elem, idx) => {
              return (
                <SwiperSlide className="w-container-sm md:px-7" key={idx}>
                  <div className="text-white">
                    <div className="flex items-center gap-3 mb-6">
                      <p className="text-[#E2E2E2] font-bold">
                        {elem.title}
                      </p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 38" fill="none">
                        <path d="M2.2959 1.70312L2.2959 36.209" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                      </svg>
                      <p className="text-[#E2E2E2] font-medium">{elem.fullTitle}</p>
                    </div>
                    <div className="chart-div-mobile py-5 md:px-10">
                      <img
                        className="w-container-sm"
                        src={elem.chartImage}
                        alt={`${elem.title} data in the form of chart`}
                      />
                    </div>
                    <h3 className="font-medium leading-[130%] mt-7 max-w-md">
                      {elem.heading}
                    </h3>
                    <p className="text-[#CDCDCD] font-regular mt-2 mb-6 max-w-xs md:max-w-md">
                      {elem.desc}
                    </p>
                    <HeroBtn
                      role="link"
                      ariaLabel={elem.buttonText}
                      size="small"
                      text={elem.buttonText}
                      // onClick={}
                      type="button"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default CardsOnScroll;