import React, { useLayoutEffect } from 'react'
import { RenderMarkdown } from 'organisms'

const content = `
Last Updated: January, 2024


##### 1. Introduction:
Welcome to www.cpoassist.com (the "Website"), owned and operated by Evolog Private Limited ("we," "us," or "our"). This Cookies Policy explains how we use cookies and similar technologies on our Website.

##### 2. What Are Cookies?
Cookies are small text files that are placed on your device when you visit a website. They are widely used to enhance your online experience, personalize content, and analyze website traffic.


##### 3. Types of Cookies We Use:
a. Essential Cookies:

These cookies are necessary for the proper functioning of the Website and enable you to navigate through its features.

b. Analytical/Performance Cookies:

These cookies allow us to analyze how visitors use the Website, helping us improve its functionality and user experience.

c. Functionality Cookies:

Functionality cookies enable us to remember your preferences, such as language settings, and provide enhanced features.

d. Targeting/Advertising Cookies:

These cookies are used to deliver personalized advertisements and content based on your interests.


##### 4. Third-Party Cookies:
We may also use third-party cookies for analytics and advertising purposes. These cookies are governed by the respective privacy policies of the third parties providing them.


##### 5. How to Manage Cookies:
Most web browsers allow you to control and manage cookies through browser settings. You can block or delete cookies, but be aware that certain features of the Website may not function properly if you do.

##### 6. Changes to This Policy:
We may update this Cookies Policy from time to time. Any changes will be posted on this page with the "Last Updated" date.

##### 7. Contact Us:
If you have any questions or concerns about our use of cookies, please contact us at [legal@cpoassist.com](mailto:legal@cpoassist.com).

##### 8. Consent:
By using our Website, you consent to the use of cookies as described in this Cookies Policy.

`

export default function CookiesPolicy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='bg-[#040001] text-white'>
      <div className="pt-[4.5rem] md:pt-24 mb-4 lg:pt-32 lg:pb-10 ">
        <h3 className="font-medium text-center px-5 sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg leading-relaxed ">
          Cookies policy
        </h3>
      </div>
      <div className="markdown-wrapper md:max-w-xl lg:max-w-2xl xl:max-w-3xl 2xl:max-w-5xl sm:px-5 px-3 mx-auto blade-bottom-padding-lg ">
        <RenderMarkdown content={content} />
      </div>
    </div>
  )
}
