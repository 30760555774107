import React, { ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './markdown.css'

type Props = { children: ReactNode }
type Anchor = { children: ReactNode; href: string }

function Heading1({ children }: Props) {
  return <h1 className="font-bold text-[#ea5556] text-2xl lg:text-[30px] xl:text-[40px]">{children}</h1>
}

function Heading2({ children }: Props) {
  return <h2 className='font-bold mb-5 lg:mb-10 text-[#8d5555] text-2xl lg:text-[30px] xl:text-[40px]'>{children}</h2>
}

function Heading3({ children }: Props) {
  return <h3 style={{backgroundImage: "linear-gradient(90deg, #FDA172 0%, #FD6142 50%, #FCAC55 100%)"}} className='font-bold lg:text-[30px] mt-10 xl:text-40 text-transparent bg-clip-text'>{children}</h3>
}

function Heading4({ children }: Props) {
  return <h4 className="font-bold text-40">{children}</h4>
}

function Heading5({ children }: Props) {
  return <h5 className=" ">{children}</h5>
}
function Heading6({ children }: Props) {
  return <h6 className="font-regular">{children}</h6>
}

function ListItem({ children }: Props) {
  return <li className="">{children}</li>
}
function OrderedList({ children }: Props) {
  return <ol className="">{children}</ol>
}

function UnorderedList({ children }: Props) {
  return <ul className="">{children}</ul>
}

function Para({ children }: Props) {
  return <p className="">{children}</p>
}

function Table({ children }: Props) {
  return <table className="">{children}</table>
}

const markdownConfig:any = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  h6: Heading6,
  p: Para,
  a: Anchor,
  li: ListItem,
  ul: UnorderedList,
  ol: OrderedList, 
  table: Table
}

function Anchor(props: React.HTMLAttributes<HTMLAnchorElement>) {
  const { children } = props
  return (
    <a {...props} target="_blank" className=" ">
      {children}
    </a>
  )
}
export { markdownConfig }

export default function RenderMarkdown({ content }: { content: string }) {
  return <ReactMarkdown remarkPlugins={[remarkGfm]} components={markdownConfig}>{content}</ReactMarkdown>
}
