import React, { useEffect, useLayoutEffect, useRef } from "react";
import ComingCards from "./comingCards";
import CardsOnScroll from "./cardsOnScroll";
import FeatureCarousel from "./featureCarousel";
import WinningHearts from "./winningHearts";
import WhyCPOA from "./whyCPOA";
import Facilities from "./facilities";
import Platform from "./platform";
import "./index.css";

import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import BlogSection from "./blogSection";
gsap.registerPlugin(ScrollToPlugin);

const PageHome = () => {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (
        window.location.hash === "#ecosystem" ||
        window.location.hash === "#services-and-tools" ||
        window.location.hash === "#about-us" ||
        window.location.hash === "#blogs"
      ) {
        gsap.to(window, { scrollTo: window.location.hash });
      } else {
        gsap.to(window, { scrollTo: 0 });
      }
    });

    return () => ctx.revert();
  }, [window.location.hash]);

  return (
    <>
      <Platform />
      <Facilities />
      <FeatureCarousel />
      <CardsOnScroll />
      <WhyCPOA />
      {/* <WinningHearts />
      <ComingCards /> */}
      <BlogSection />
    </>
  );
};

export default PageHome;
