import React, { useState, useRef, useEffect, useReducer } from "react";
import logo from "assets/globals/logo.svg";
import { HeroBtn } from "atoms/buttons";
import { useNavigate } from "react-router-dom";
import { Anchor, TextNavAnchor } from "atoms/links";
import { CSSTransition } from "react-transition-group";
import "./stylesOrganism.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MobileNavModal from "./mobileNav";
import { ContactUs } from "screens/index";
import { toast } from "react-toastify";

gsap.registerPlugin(ScrollTrigger);

export default function Navbar() {
  const [mobileNav, toggleMobileNav] = useReducer((s) => !s, false);
  const [isNavFixed, setisNavFixed] = useState(false);
  const [isOpen, toggleState] = useReducer((s) => !s, false);
  const [showModal, setShowModal] = useState(false);

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggleDropdown = () => {
    setIsOpenMenu(!isOpenMenu);
  };
  const dropdownRef = useRef<any>();
  const solutionsRef = useRef<any>();

  const navigate = useNavigate();
  const goToContact = () => {
    navigate("/contact-us");
  };

  const headerWrapperRef = useRef(null);

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileNav]);

  const handleScroll = () => {
    const scrollPos = window.scrollY;
    if (scrollPos > 80) {
      setisNavFixed(true);
      setIsOpenMenu(false);
    } else {
      setisNavFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const showAnim = gsap
      .from(headerWrapperRef.current, {
        yPercent: -300,
        paused: true,
        duration: 0.3,
      })
      .progress(1);
    ScrollTrigger.create({
      start: "top top",
      end: 99999,
      onUpdate: (self) => {
        if (self.direction === -1) {
          showAnim.play();
        } else {
          showAnim.reverse();
        }
      },
    });
  }, []);

  const closeDropdown = () => {
    setIsOpenMenu(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !solutionsRef.current.contains(e.target)
      ) {
        setIsOpenMenu(false);
        console.log("Out");
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Attach a click event listener to the document

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isOpenMenu) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpenMenu]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const checkFormStatus = (success:any) => {
    if (success === true) {
        toast.success("Form Submitted Successfully")
    }else{
        toast.warn('Something went wrong, Please try later!')
    }
  }



  return (
    <nav className="">
      <div
        
        ref={headerWrapperRef}
        className={`${
          isNavFixed
            ? "shadow-header backdrop-blur3xl bg-transparent bgopacity-40"
            : "bg-transparent"
        } fixed w-full top-5 md:top-10 left-1/2 -translate-x-1/2 -translate-y1/2 right0 z-[9999] w-container`}
      >
        <div className="w-full md:w-[600px] w-container lg:w-[968px] 2xl:w-[968px] p-2 rounded-[51px]  backdrop-blur-sm bg-white/30">
          <header className="xlg:mt16 py2 z-50 lg:max-w3xl">
            <div className="px-5 md:px-7 w-full bg-[#FFF5F5] rounded-[51px] h-full lg:py-4 md:py-3 py-1 flex gap-4 items-center justify-between m-auto">
              <Anchor className="w-36 md:w-52 outlinenone rounded-sm outline-[#EA5455] outline-offset-4 active:outline-none" href="/">
                <img
                  src={logo}
                  className="h-full !cursor-pointer"
                  alt="Logo of CPOA with text"
                  loading="eager"
                />
              </Anchor>
  
              <div className="lg:flex gap-10 justify-end hidden">
                <ul className="list-none gap-10 lg:flex items-center hidden">
                  {/* <li className="relative">
                    <button
                      ref={solutionsRef}
                      onClick={toggleDropdown}
                      type="button"
                      className="dropdown-toggle text-black text-sm mt-[2px] border-none leading-none flex items-center opacity60 hover:opacity80"
                    >
                      Solutions
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        className={`w-5 pt-[1px] transition-all duration-500 ${
                          isOpenMenu ? '-rotate-90' : ''
                        } `}
                      >
                        <path fill="none" d="M0 0h24v24H0V0z" />
                        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                      </svg>
                    </button>
  
                    <CSSTransition
                      in={isOpenMenu}
                      timeout={400}
                      unmountOnExit
                      onEnter={(node: any) =>
                        gsap.fromTo(
                          node,
                          { opacity: 0, y: 10 },
                          { opacity: 1, y: 0, duration: 0.5 },
                        )
                      }
                      onExiting={(node: any) =>
                        gsap.to(node, { opacity: 0, y: 10, duration: 0.5 })
                      }
                    >
                      <div className="dropdown" ref={dropdownRef}>
                        <button
                          type="button"
                          onClick={() => setIsOpenMenu(false)}
                          className="dropdown-content flex flex-col gap-3 mt-2 bg-dark rounded-lg px-5 pt-4 pb-5 min-w-[200px]"
                        >
                          <TextNavAnchor
                            color="dark"
                            href="/solutions/product-development"
                            text="Product Development / Scaling"
                            className="whitespace-nowrap"
                            size="small"
                          />
  
                          <TextNavAnchor
                            color="dark"
                            href="/solutions/business-process-transformation"
                            text="Business Process Transformation"
                            className="whitespace-nowrap"
                            size="small"
                          />
  
                          <TextNavAnchor
                            color="dark"
                            href="/solutions/engineering-sustainability"
                            text="Engineering Sustainability"
                            className="whitespace-nowrap"
                            size="small"
                          />
  
                          <TextNavAnchor
                            color="dark"
                            href="/solutions/business-innovation"
                            text="Business Innovation"
                            className="whitespace-nowrap"
                            size="small"
                          />
  
                          <TextNavAnchor
                            color="dark"
                            href="/solutions/data-management"
                            text="Data Management"
                            className="whitespace-nowrap"
                            size="small"
                          />
                        </button>
                      </div>
                    </CSSTransition>
                  </li> */}
                  <li>
                    <TextNavAnchor
                      size="base"
                      color="dark"
                      text="Ecosystem"
                      href="/#ecosystem"
                      
                    />
                  </li>
                  <li>
                    <TextNavAnchor
                      size="base"
                      color="dark"
                      text="Services & tools"
                      href="/#services-and-tools"
                    />
                  </li>
                  <li>
                    <TextNavAnchor
                      size="base"
                      color="dark"
                      text="About us"
                      href="/#about-us"
                    />
                  </li>
                  <li>
                    <TextNavAnchor
                      size="base"
                      color="dark"
                      text="Blogs"
                      href="/#blogs"
                    />
                  </li>
                </ul>
                
              </div>
              <div className="lg:block hidden">
                <HeroBtn
                    ariaLabel="For bookind demo"
                    role="link"
                    size="base"
                    text="Book a demo"
                    onClick={toggleModal}
                    type="button"
                />
                
                               
              </div>
  
              {/* Toggle button */}
              <button
                type="button"
                className="lg:hidden block"
                onClick={toggleState}
              >
                <svg
                  stroke="#EA5455"
                  fill="#EA5455"
                  strokeWidth="0"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  className="h-6 my-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </header>
        </div>
      </div>
      <div
        className={`${
          isOpen ? "translate-x-0" : "translate-x-[200%]"
        } transition z-[9999] duration-500 fixed inset-0 h-screen w-screen bg-black text-white`}
      >
        <MobileNavModal toggle={toggleState} />
      </div>
      <div
                                    className={`fixed top-0 ${showModal ? "flex" : "hidden"} bg-black bg-opacity-70 right-0 left-0 z-[999999] justify-center items-center wfull md:inset-0 h-[calc(100%)] md:max-h-full contact-us-popup`}
                                >
                                    <ContactUs toggle={showModal} setToggle={setShowModal} checkFormStatus={checkFormStatus} />
                                            
                                </div>
    </nav>
    
  );
}
