import React, { useEffect, useRef } from 'react'
import right_pointer_line from 'assets/home/features/right-pointer-line.svg'
import left_pointer_line from 'assets/home/features/left-pointer-line.svg'
import radiator from 'assets/home/features/radiator-skeleton.svg'
import radiator_center_logo from 'assets/home/features/raditor-center-logo.svg'
import card_icon_1 from 'assets/home/features/card-icon-1.webp'
import card_icon_2 from 'assets/home/features/card-icon-2.webp'
import card_icon_3 from 'assets/home/features/card-icon-3.webp'
import radiator_icon_skeleton from 'assets/home/features/radiator-icon-skeleton.svg'
import radiator_icon_fill from 'assets/home/features/radiator-icon-fill.svg'

import agility from 'assets/home/features/agility.webp'
import safety from 'assets/home/features/safety.webp'
import trust from 'assets/home/features/trust.webp'
import efficiency from 'assets/home/features/effeciency.webp'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)


const Facilities = () => {
    
    const ecosystem = useRef<any>()
    const wrapper = useRef<any>()

    const data = [
        {
            icon: agility,
            title: "Agility",
            desc: "Faster decision-making with optimised solutions."
        },
        {
            icon: safety,
            title: "Safety",
            desc: "Failproof operations with certified equipment and SOPs."
        },
        {
            icon: trust,
            title: "Trust",
            desc: "Reliable execution with experienced and certified vendors."
        },
        {
            icon: efficiency,
            title: "Efficiency",
            desc: "CapEx and OpEx optimisation with end-to-end single-window solutions."
        },
    ]

    useEffect(() => {
        gsap.to(wrapper.current, {
          scrollTrigger: {
            trigger: wrapper.current,
            start: 'top 60%',
            onEnter: () => {
              wrapper?.current?.classList.add('title-active')
            },
          },
        })
    }, [])

  return (
    <section id='ecosystem' ref={ecosystem} className='facilities-section relative after:content-[""] after:absolute after:top-0 after:blur-md after:pointer-events-none after:left-1/2 after:-translate-x-1/2  after:h-full after:w-full after:bg-[url(assets/home/features/facility-gradient-ellipse.svg)] after:bg-no-repeat after:bg-contain' >
        <div ref={wrapper} className='min-h-screen blade-top-padding-lg blade-bottom-padding-lg text-white w-container  bg-cover overflow-x-hidden'>
            <div className='flex flex-col justify-center items-center gap-7 facilities-text'>
                <h3 className='text-center font-medium translate-y-4 opacity-0'>Facilitating seamless <br className='hidden md:block' /> <strong style={{backgroundImage: "linear-gradient(90deg, #FDA172 0%, #FD6142 50%, #FCAC55 100%)"}} className='fontbold bg-clip-text text-transparent block'>EV charging network expansion</strong></h3>
                <p className='font-regular text-[#AEAEAE] max-w-2xl text-center translate-y-4 opacity-0'>CPO Assist is a pioneering startup dedicated to revolutionising the electric vehicle (EV) charging
                    landscape and was incorporated with the vision to democratise location intelligence for CPOs to rapidly
                    grow and manage their EV charging network with confidence using our next generation SaaS platforms.
                </p>
            </div>
            <div className='blade-top-margin'>
                <div className='grid grid-cols-1 max-sm:w-container sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10 2xl:gap-x-20 2xl:w-container'>
                    <div className='relative'>
                        <div className='facilities-card opacity-0 py-5 pl-5 pr-9 2xl:pr-14 min-h-full max-h[300px]'>
                            <img className='h-12' src={card_icon_1} alt="Looking icon" />
                            <p className='mt-5 font-medium'>CPOs looking for:</p>
                            <div className='mt-5 flex flex-col gap-2'>
                                <p className='text-sm text-[#949494] font-regular'>Sites to set up multiple EV charging stations.</p>
                                <p className='text-sm text-[#949494] font-regular'>Insightful site survey reports for better planning.</p>
                                <p className='text-sm text-[#949494] font-regular'>Experienced team and precision tools for installation of chargers.</p>
                            </div>
                        </div>
                        <div className='absolute hidden lg:block z-10 opacity-70 translate-x-1/2 left-pointer-line'>
                            <img src={left_pointer_line} alt="White dashhed line" />
                        </div>

                    </div>  
                    <div className='facilities-card opacity-0 py-5 pl-5 pr-9 2xl:pr-14 min-h-full max-h[300px]'>
                        <img className='h-12' src={card_icon_2} alt="EV fleet icon" />
                        <p className='mt-5 font-medium'>EV Fleet operator looking for:</p>
                        <div className='mt-5 flex flex-col gap-2'>
                            <p className='text-sm text-[#949494] font-regular'>Hub charging location for captive utilisation.</p>
                            <p className='text-sm text-[#949494] font-regular'>Customised solutions for fleet-specific needs and challenges.</p>
                            <p className='text-sm text-[#949494] font-regular'>Hub management and charger repair and uptime services.</p>
                        </div>
                    </div>  
                    <div className='relative sm:translate-x-1/2 lg:translate-x-0'>
                        <div className='facilities-card opacity-0 py-5 pl-5 pr-9 2xl:pr-14 min-h-full max-h[300px]'>
                            <img className='h-12' src={card_icon_3} alt="Parking logo" />
                            <p className='mt-5 font-medium'>EV Charger OEMs looking for:</p>
                            <div className='mt-5 flex flex-col gap-2'>
                                <p className='text-sm text-[#949494] font-regular'>Annual and comprehensive maintenance services.</p>
                                <p className='text-sm text-[#949494] font-regular'> Repair, replacement and recovery operations.</p>
                                <p className='text-sm text-[#949494] font-regular'>Trained installation and commissioning partners.</p>
                            </div>
                        </div>  
                        <div className='right-pointer-line hidden lg:block opacity-70 absolute z-10 -translate-x-1/2'>
                            <img src={right_pointer_line} alt="White dashhed line" />
                        </div>
                    </div>
                </div>
               <div className='relative flex justify-center'>
                    <div className='w-container w-fit min-w-[400px] relative overflow-hidden radiator-parent mt-12 lg:mt-0  lg:pt-10 xl:pt-32 min-[1440px]:pt-40 2xl:pt-20'>
                        <div className='absolute top-[25%] lg:top-[40%] xl:top-[45%] 2xl:top-[37%] left-1/2 -translate-x-1/2 -translate-y-1/2'>
                            <img className='absolute max-sm:h-9 center-icon-skeleton' src={radiator_icon_skeleton} alt="Electricity symbol logo" />
                            <img className='max-sm:h-9 center-icon-fill' src={radiator_icon_fill} alt="Electricity symbol logo" />
                        </div>
                        <img className='lg:h-[350px] 2xl:h-full' src={radiator} alt="A semicircle with three different dashhed border layer" />
                        <div className='rotate-radiator max-[280px]:h-[100px] max-[280px]:w-[100px] h-[330px] w-[330px] md:h-[575px] md:w-[575px] lg:h-[620px] lg:w-[620px] 2xl:w-[800px] 2xl:h-[800px] top-full rounded-full left-1/2 absolute'>
                        </div>
                    </div>
                    <div className="font-medium absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 center-logo before:top-[-50%] after:bottom-[-90%] md:before:top-[-25%] md:after:bottom-[-30%] lg:before:top-[-15%] lg:after:bottom-[-25%] 2xl:before:top-[-25%] 2xl:after:bottom-[-25%]"><img className=' h-20 md:h-32 lg:h-44 ' src={radiator_center_logo} alt="CPOA Logo" /></div>
               </div>
                <div className='w-container  w-fit lg:flex gap-10 xl:gap-5 hidden mt-12'>
                    <svg className='lg:w-72 xl:w-full opacity-70' xmlns="http://www.w3.org/2000/svg" width="354" height="233" viewBox="0 0 354 233" fill="none">
                        <path opacity="0.5" d="M1.38202 231.719V172.599C1.38202 167.478 5.10033 163.326 9.68649 163.326H344.899C349.485 163.326 353.203 159.174 353.203 154.052V122.754C353.203 117.632 349.485 113.48 344.899 113.48H172.58C167.994 113.48 164.276 109.328 164.276 104.206V1.03723" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.5 6"/>
                    </svg>
                    <svg className='lg:w-72 xl:w-full opacity-70' xmlns="http://www.w3.org/2000/svg" width="354" height="233" viewBox="0 0 354 233" fill="none">
                        <path opacity="0.5" d="M353.096 231.719V172.599C353.096 167.478 349.377 163.326 344.791 163.326H9.57891C4.99275 163.326 1.27441 159.174 1.27441 154.052V122.754C1.27441 117.632 4.99275 113.48 9.57891 113.48H181.897C186.483 113.48 190.202 109.328 190.202 104.206V1.03723" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.5 6"/>
                    </svg>
                </div>
                <div className=' w-full lg:max-w-[820px] mx-auto xl:max-w-[920px] 2xl:max-w-[920px] flex flex-wrap justify-center lg:justify-between mt-36 gap-10 lg:gap-0 lg:mt-3'>
                    {
                        data.map((elem,idx)=>{
                            return(
                                <div key={idx} className={`max-w-[210px] flex flex-col items-center gap-[10px] features-dets relative ${idx === 1 || idx === 2 ? 'before:hidden lg:before:-mt-5 xl:before:mt-0 lg:before:block lg:before:h8 xl:before:h[52px]' : ''}`}>
                                    <img className='h-16' src={elem.icon} alt={`${elem.title} icon`} />
                                    <p className='text-sm font-medium'>{elem.title}</p>
                                    <p className='text-sm text-center font-regular text-[#949494]'>{elem.desc}</p>
                                </div>
                            )
                        })
                    }
                </div>
                

            </div>
        </div>
    </section>
  )
}

export default Facilities