import React, { useState } from "react";
import mark from "assets/globals/footer-logo-mark.svg";
import footerLogo from "assets/globals/footer-logo.svg";
import { TextAnchor, Anchor, ExternalAnchor, TextNavAnchor } from "atoms/links";
import { HeroBtn } from "atoms/buttons";
import { useNavigate } from "react-router-dom";

import ellipse from 'assets/footer/ellipse.svg'

import "./footer.css"
import { ContactUs } from "screens/index";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


export default function Footer() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  // const goToContact = () => {
  //   navigate("/contact-us");
  // };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const checkFormStatus = (success:any) => {
    if (success === true) {
        toast.success("Form Submitted Successfully")
    }else{
        toast.warn('Something went wrong, Please try later!')
    }
  }

  return (
    <footer className="text-white border-t-gray border-t border-opacity-10 bg-cover bg-center blade-top-padding bg-[url(assets/footer/bg.webp)] footer relative overflow-hidden z10">
      <img className="absolute top-0 z[-1] hidden md:block pointer-events-none" src={ellipse} alt="Ellipse gradient" />
      <div className="flex flex-col items-center justify-center gap-y-8">
        <div className="footer-centeric-icon relative">
          <img className="relative z-[2] h-28 xl:h-full" src={mark} alt="CPO - Assist logo" />
        </div>
        <h3
          className="text-center font-medium text-48 tracking-tighter leading-tight text-[#8D5555] max-w-[350px] lg:max-w-none w-container"
        >
          <strong className="text-[#EA5556]">Get in touch.</strong><strong> A sorted EV
          charging <br className="hidden md:block" /> network expansion
          experience awaits.</strong>
        </h3>
        <HeroBtn
          ariaLabel="Redirect to the contact us page"
          role="link"
          size="small"
          text="Contact us"
          onClick={toggleModal}
          type="button"
        />
        <div
          className={`fixed top-0 ${showModal ? "flex" : "hidden"} bg-black bg-opacity-70 right-0 left-0 z-[999999] justify-center items-center wfull md:inset-0 h-[calc(100%)] md:max-h-full contact-us-popup`}
        >
          <ContactUs toggle={showModal} setToggle={setShowModal} checkFormStatus={checkFormStatus} />
                                            
        </div>
      </div>
      <div className="blade-top-margin py-5 lg:py-9 xl:py-14 border-t border-[#4B4B4B]">
        <div className="w-container px-5 md:px-16 flex flex-col gap-10 lg:gap-0 lg:flex-row itemscenter md:items-start lg:items-center justify-between relative">
          <a href="/" className="outline-[#EA5455] rounded-sm outline-offset-4">
            <img
              className="h-10 xl:h-12"
              src={footerLogo}
              alt="CPO Assist full logo"
            />
          </a>
          <div className="lg:flex gap-y-5 gap-x-16  md:gap-x-20 lg:gap-5 xl:gap-10 grid grid-cols-2 justify-center items-center xl:absolute xl:top-1/2 xl:left-1/2 xl:-translate-x-1/2 xl:-translate-y-1/2">
            <TextNavAnchor
              size="base"
              color="dark"
              text="Ecosystem"
              href="/#ecosystem"
            />
            
            <TextNavAnchor
              size="base"
              color="dark"
              text="About us"
              href="/#about-us"
            />
            <TextNavAnchor
              size="base"
              color="dark"
              text="Services & tools"
              href="/#services-and-tools"
            />
            <TextNavAnchor
              size="base"
              color="dark"
              text="Blogs"
              href="/#blogs"
            />
            <div onClick={toggleModal}>
              <TextNavAnchor
                size="base"
                color="dark"
                text="Contact us"
                href=""
              />
            </div>
            
            
            {/* <TextAnchor size="base" color="light" href="/blogs" text="Blogs" /> */}
          </div>
          <div className="flex items-center gap-4">
            <ExternalAnchor label="LinkedIn Icon" className="outline-[#EA5455] rounded-sm outline-offset-4" href="https://www.linkedin.com/company/cpoassist/">
              <svg className="hover:fill-[#EA5556] fill-white group transition-all duration-300" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.895508 2.48482C0.895508 1.42213 1.78399 0.561523 2.88152 0.561523H25.7868C26.8843 0.561523 27.7728 1.42387 27.7728 2.48656V25.5138C27.7728 26.5765 26.8843 27.4388 25.7868 27.4388H2.88152C1.78399 27.4388 0.895508 26.5765 0.895508 25.5138V2.48482Z" fill="inherit"/>
                <path className="group-hover:fill-white transition-all duration-300" fillRule="inherit" clipRule="evenodd" d="M9.04318 23.0587V10.9231H4.98406V23.0604L9.04318 23.0587ZM7.01362 9.26638C8.42822 9.26638 9.30973 8.33435 9.30973 7.16888C9.2836 5.97902 8.42822 5.07312 7.03976 5.07312C5.65129 5.07312 4.74365 5.97902 4.74365 7.16888C4.74365 8.33435 5.62516 9.26638 6.98749 9.26638H7.01362ZM11.2888 23.0587H15.3479V16.2819C15.3479 15.9195 15.3758 15.5572 15.482 15.2976C15.7764 14.5729 16.4437 13.822 17.5656 13.822C19.0342 13.822 19.6213 14.937 19.6213 16.5676V23.0587H23.6804V16.0989C23.6804 12.3708 21.677 10.6374 19.0081 10.6374C16.8165 10.6374 15.8548 11.8534 15.3218 12.6809H15.3479V10.9214H11.2888C11.341 12.0607 11.2888 23.0587 11.2888 23.0587Z" fill="#1C1D1F"/>
              </svg>
            </ExternalAnchor>
            <ExternalAnchor label="Twitter Icon" className="outline-[#EA5455] rounded-sm outline-offset-4" href="https://twitter.com/cpoassist">
            <svg className="hover:fill-[#EA5556] fill-white transition-all duration-300" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 19 17" fill="none">
              <path d="M0.717527 0.398438L7.63758 9.42874L0.673828 16.7708H2.24109L8.33784 10.3427L13.2639 16.7708H18.5973L11.2879 7.23251L17.7697 0.398438H16.2024L10.5876 6.31854L6.05098 0.398438H0.717527ZM3.0223 1.52513H5.4725L16.2922 15.6439H13.842L3.0223 1.52513Z" fill="inherit"/>
            </svg>
            </ExternalAnchor>
          </div>
        </div>
        <div className="opacity-50 flex items-center justify-start px-5 md:px-16 w-container lg:justify-center mt-5 text-sm gap-2 font-light">
          <Link to={"/privacy-policy"}>Privacy policy</Link>
          <span>/</span>
          <Link to={"/cookies-policy"}>Cookies policy</Link>

        </div>
      </div>
    </footer>
  );
}
